import React from "react";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Banner from "../components/Banner";
import SeO from "../components/SEO";
import slugify from "slugify";
import { Disqus } from "gatsby-plugin-disqus";

const PostTemplate = ({ data }) => {
  const singlepost = data.mdx.frontmatter;
  const { title, image, category, Update, multipleImages } = singlepost;
  const postBody = data.mdx.body;
  const postId = data.mdx.id;
  const description = data.mdx.excerpt;
  const tableofcontent = data.mdx.tableOfContents;
  /* console.log(tableofcontent); */
  // console.log(title)
  // console.log(Update);
  // console.log(postBody)
  // console.log(singlepost)
  const currentURL = typeof window !== "undefined" ? window.location.href : "";

  const disqusConfig = {
    url: currentURL,
    identifier: postId,
    title: title,
  };

  return (
    <Layout>
      <SeO title={title} description={description} />
      <section className="postTemplate">
        {/* post info */}
        <article>
          <GatsbyImage
            image={getImage(image)}
            alt={title}
            className="main-bg"
          />
          <div className="post-info">
            {category.map((tag, index) => {
              const tagCategory = slugify(tag, { lower: true });
              return (
                <span className="category posttemplate" key={index}>
                  <Link className="categoryLink" to={`/${tagCategory}`}>
                    {tagCategory}
                  </Link>
                </span>
              );
            })}
            <h2>{title}</h2>
            <p>{Update}</p>
            <div className="underline"></div>
          </div>
          <MDXRenderer multipleImages={multipleImages}>{postBody}</MDXRenderer>
          <Disqus config={disqusConfig} className="commentSection" />
        </article>
        <article>
          <Banner />
          {typeof tableofcontent.items === "undefined" ? null : (
            <ul className="tOc">
              <h4 style={{ fontWeigth: "bold" }}>Table of contents</h4>
              <div className="underline"></div>
              <div className="InnerScroll">
                {tableofcontent.items.map((i) => (
                  <li key={i.url}>
                    <a href={i.url} key={i.url}>
                      {i.title}
                    </a>
                  </li>
                ))}
              </div>
            </ul>
          )}
        </article>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        category
        Update(formatString: "MMM,Do,YYYY")
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        multipleImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        readTime
        title
        slug
      }
      excerpt
      body
      id
      tableOfContents
    }
  }
`;

export default PostTemplate;
